/**
 * External dependencies.
 */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import FacebookIcon from '../../icons/facebook-icon';
import TwitterIcon from '../../icons/twitter-icon';
import LinkedinIcon from '../../icons/linkedin-icon';
import InstagramIcon from '../../icons/instagram-icon';

import './style.scss';

const SocialLinks = ( { items, socialLinksParent } ) => {

	const [ isSticky, setIsSticky ] = useState( false );
	const socialLinksWrapper = useRef( null );

	const socialIcons = {
		facebook: <FacebookIcon />,
		twitter: <TwitterIcon />,
		linkedin: <LinkedinIcon />,
		instagram: <InstagramIcon />
	};

	let timer = null;

	const stickyFooter = () => {

		if ( 768 <= window.innerWidth ) {
			return;
		}

		if ( null !== timer ) {
			clearTimeout( timer );
		}

		timer = setTimeout( () => {

			setIsSticky( true );

		}, 300 );

		setIsSticky( false );

	}

	useEffect( () => {
		window.addEventListener( 'scroll', stickyFooter );

		window.addEventListener( 'load', setIsSticky( true ) );

		return () => window.removeEventListener( 'scroll', stickyFooter );
	}, [] ); // eslint-disable-line

	return (
		<div className={ `site-footer__social-links-wrap ${ isSticky ? 'is-sticky' : '' }` } ref={ socialLinksWrapper }>
			<ul className="site-footer__social-links">
				{ items.map( socialLink => (
					<li key={ socialLink.iconName }>
						<a className={ `${ socialLink.iconName }-icon-link` } title={ socialLink.iconName } target="__blank" href={ socialLink.iconUrl }>
							{ socialIcons[ socialLink.iconName ] }
						</a>
					</li>
				) ) }
			</ul>
		</div>
	)
}

SocialLinks.propTypes = {
	items: PropTypes.array,
	socialLinksParent: PropTypes.func.isRequired
};

SocialLinks.defaultProps = {
	items: []
};

export default SocialLinks;
