/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import TypingLoader from '../../typing-loader';

/**
 * ScreenZero Component.
 *
 * @return {jsx}
 */
const ScreenZero = () => {
	return (
		<div className="screen-zero">
			<TypingLoader className="screen-zero__container" />
		</div>
	);
};

export default ScreenZero;
