/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import './style.scss';

/**
 * TypingLoader Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const TypingLoader = ( { className } ) => {

	return (
		<div className={ className } >
			<div className="typing-animation">
				<span className="typing-animation__dot"/>
				<span className="typing-animation__dot"/>
				<span className="typing-animation__dot"/>
			</div>
		</div>
	);
};

TypingLoader.propTypes = {
	className: PropTypes.string,
}

export default TypingLoader;
