/**
 * External dependencies.
 */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import Column from './column';
import SocialLinks from './social-links';
import { flatListToHierarchical } from '../../utils/functions';

import './style.scss';
import FooterLogo from '../icons/footer-logo';

/**
 * Footer Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */

const Footer = ({ data }) => {

	const socialLinksParent = useRef(null);

	const getSocialLinksParent = () => {
		return socialLinksParent;
	}

	const {
		footer: { copyrightText, brandLogos, socialLinks },
		footerMenuCol1PoweredBy,
		footerMenuCol2PoweredBy
	} = data.OWGraphQL;

	const date = new Date();

	return (
		<footer className="site-footer-container">
			<div className="site-footer__top row">
				<div className="site-footer__social-links-column small-12 columns" ref={socialLinksParent}>
					<SocialLinks items={socialLinks} socialLinksParent={getSocialLinksParent} />
				</div>
				<div className="site-footer__divider-top small-12 columns">
					<div className="divider" />
				</div>
				<div className="site-footer__logo-column small-12 columns show-for-small-only">
					<Link to="/" className="site-footer__logo-link">
						<FooterLogo />
					</Link>
				</div>
				<Column className="footer-menu-column margin-left-right-auto small-12 medium-5 large-3 columns" menus={footerMenuCol1PoweredBy ? flatListToHierarchical(footerMenuCol1PoweredBy.nodes) : ''} />
				<Column className="footer-menu-column margin-left-right-auto small-12 medium-5 large-3 columns" menus={footerMenuCol2PoweredBy ? flatListToHierarchical(footerMenuCol2PoweredBy.nodes) : ''} hasLoginLink={true} />

			</div>
			<div className="site-footer__bottom row">
				<div className="site-footer__divider-bottom small-12 column">
					<div className="divider small-12 column" />
				</div>
				<ul className="site-footer__brand-logos small-12 medium-6 large-4 column">
					{brandLogos.map(brandLogo => (
						<li className="site-footer__brand-logo-item" key={brandLogo.brandName}>
							<img className="site-footer__brand-logo" src={brandLogo.brandImage} alt={brandLogo.brandName} />
						</li>
					))}
				</ul>
				<div className="site-footer__copyright-text small-12 medium-6 large-8 column">
					{copyrightText} {date.getFullYear()}
				</div>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	data: PropTypes.object,
};

Footer.defaultProps = {
	data: {},
};

export {
	Footer
}

export default () => {
	return (
		<StaticQuery
			query={graphql`
				query FooterQueryPoweredBy {
					OWGraphQL {
						footer: getFooter {
							copyrightText
							footerLogo
							brandLogos {
								brandName
								brandImage
							}
							socialLinks {
								iconUrl
								iconName
							}
						}
						footerMenuCol1PoweredBy: menuItems(where: {location: OW_MENU_FOOTER_COL_1_POWERED_BY}) {
							nodes {
								key: id
								parentId
								label
								url
								path
							}
						}
						footerMenuCol2PoweredBy: menuItems(where: {location: OW_MENU_FOOTER_COL_2_POWERED_BY}) {
							nodes {
								key: id
								parentId
								label
								url
								path
							}
						}
					}
				}
			`}
			render={data => <Footer data={data} />}
		/>
	)
};
