/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from './../../link';
import { get } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import ScreenZero from './screen-zero';
import LottiePlayer from '../../lottie-player';
import SkipButton from './skip-btn';
import { handleTestimonialsHeight } from './../../../utils/functions';

import './style.scss';

/**
 * Home Hero Component.
 *
 * @param {Object} data Component props.
 *
 * @return {jsx}
 */
const Hero = ( { meta, device, showAnimation } ) => {
	const { preHeadings, postHeadings, links } = meta;
	const [ showPreHeadingsLottie, togglePreHeadingsLottie ] = useState( true );
	const [ canStartSymbolLottie, toggleStartSymbolLottie ] = useState( false );
	const [ isSkip, setSkip ] = useState( false );
	const [ canShowLoader, toggleLoader ] = useState( true );

	const lottieDevice = 'desktop' === device ? device : 'mobile';
	const staticImageUrl = postHeadings[ lottieDevice ] ? postHeadings[ lottieDevice ].staticImage.sourceUrl : '';
	const containerClass = classnames( 'home-sections home-hero', {
		'home-hero--has-animated-symbol': showAnimation,
	} );

	const handleSkipClick = () => {
		togglePreHeadingsLottie( false );
		setSkip( true );
		window.scroll( 0, 0 );
		document.body.classList.remove( 'screen-animating' );
		handleTestimonialsHeight();
	};

	get( preHeadings, 'mobile[lottie][mediaItemUrl]' );

	return (
		<>
			{ showAnimation && canShowLoader && (
				<ScreenZero />
			) }
			{ showAnimation && showPreHeadingsLottie && (
				<div className="pre-headings-stage">
					<LottiePlayer
						mobileJsonUrl={ get( preHeadings, 'mobile[lottie][mediaItemUrl]' ) }
						desktopJsonUrl={ get( preHeadings, 'desktop[lottie][mediaItemUrl]' ) }
						device={ lottieDevice }
						className="pre-headings-lottie-container"
						onStart={ () => {
							toggleLoader( false );
							document.body.classList.add( 'screen-animating' );
						} }
						onComplete={ () => {
							togglePreHeadingsLottie( false );
							toggleStartSymbolLottie( true );
						} }
					/>
					<SkipButton onClick={ handleSkipClick } />
				</div>
			) }
			<section className={ containerClass }>
				<div className="row align-center">
					<div className="medium-10 small-12 column">
						{ false === isSkip && showAnimation ? (
							<LottiePlayer
								mobileJsonUrl={ get( postHeadings, 'mobile[lottie][mediaItemUrl]' ) }
								desktopJsonUrl={ get( postHeadings, 'desktop[lottie][mediaItemUrl]' ) }
								className="after-headings-lottie-container"
								load={ true }
								canStart={ canStartSymbolLottie }
								onComplete={ () => {
									document.body.classList.remove( 'screen-animating' )
									handleTestimonialsHeight();
								} }
								device={ lottieDevice }
							/>
						) : (
							<div className="after-headings-lottie-container">
								<img src={ staticImageUrl } alt="OpenWeb" />
							</div>
						) }

						{ links && <div className="row align-center home-hero__links">
							{ links.map( ( link, index ) => (
								<div className="large-4 medium-6 small-6 columns" key={ `hero-button-link-${ index }` }>
									<Link { ...link.ctaLink } className="home-hero__link" />
								</div>
							) ) }
						</div> }
					</div>
				</div>
			</section>
		</>
	);
};

Hero.propTypes = {
	meta: PropTypes.object,
	device: PropTypes.string,
	showAnimation: PropTypes.bool
};

Hero.defaultProps = {
	meta: {
		postHeadings: {
			desktop: {
				lottie: {
					mediaItemUrl: '',
					mimeType: ''
				},
				staticImage: {
					sourceUrl: '',
					mediaDetails: null,
					altText: '',
					srcSet: null
				}
			},
			mobile: {
				lottie: {
					mediaItemUrl: '',
					mimeType: ''
				},
				staticImage: {
					altText: '',
					sourceUrl: '',
					srcSet: null,
					mediaDetails: null
				}
			}
		},
		preHeadings: {
			desktop: {
				lottie: {
					mediaItemUrl: '',
					mimeType: ''
				}
			},
			mobile: {
				lottie: {
					mediaItemUrl: '',
					mimeType: ''
				}
			}
		},
		links: [
			{
				ctaLink: '',
				ctaLabel: ''
			},
		]
	}
};

export default Hero;
