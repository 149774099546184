/**
 * External dependencies.
 */
import React from 'react';

/**
 * CloseBtn component.
 *
 * @return {jsx}
 */
const CloseBtn = () => {

	return(
		<svg xmlns="http://www.w3.org/2000/svg" className="ow-close-btn" width="20" height="21" viewBox="0 0 20 21">
			<g fill="none" fillRule="evenodd" stroke="#F1E9E7" strokeLinecap="square">
				<path d="M0.45 0.45L17.55 17.55" transform="translate(1 1.583)"/>
				<path d="M0.45 0.45L17.55 17.55" transform="translate(1 1.583) matrix(-1 0 0 1 18 0)"/>
			</g>
		</svg>
	);

};

export default CloseBtn;
