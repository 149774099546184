
/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

/**
 * Internal dependencies.
 */
import Nav from './nav';
import SiteLogo from '../icons/site-logo';
import { flatListToHierarchical } from "../../utils/functions"

import './style.scss';

/**
 * Header Component.
 *
 * @param {Object} data Component props.
 *
 * @return {jsx}
 */
const Header = ( { data } ) => {

	const [ menuVisible, setMenuVisibility ] = useState( false );

	const { header: { siteTitle, siteTagLine }, headerMenuItems } = data.OWGraphQL;
	const toggleMenu = () => {
		setMenuVisibility( ! menuVisible );
		document.body.classList.toggle( 'mobile-menu-open' );
	};

	const hideMenu = () => {
		if ( menuVisible ) {
			toggleMenu();
		}
	};

	const isSupport = headerMenuItems.nodes.some( item => item.label === "Support" )

	if ( ! isSupport ) {
		// Add custom menu item.
		headerMenuItems.nodes[ headerMenuItems.nodes.length ] = {
			key: "cG9zdDozNTA=",
			label: "Support",
			parentId: null,
			path: "https://support.openweb.com/hc/en-us/categories/360000624093-Users",
			url: "https://support.openweb.com/hc/en-us/categories/360000624093-Users",
			isExternalUrl: true,
		}
	}

	// Remove all menu items other then About.
	const aboutMenuItems = flatListToHierarchical(headerMenuItems.nodes).filter( item => {
		return item.label === "About" || item.label === "Support"
	} )

	return (
		<header className="header-container">
			<div className="row">
				<div className="site-header small-12 column">
					<div className="site-header__container">
						<div className="site-brand">
							<Link to="/" className="site-brand__link" onClick={ hideMenu } >
								<SiteLogo />
								<h2 className="screen-reader-text site-brand__title">{ siteTitle }</h2>
								<p className="site-brand__description">{ siteTagLine }</p>
							</Link>
						</div>
						<Nav
							headerMenuItems={ aboutMenuItems }
							menuVisible={ menuVisible }
							toggleMenu={ toggleMenu }
						/>
					</div>
				</div>
			</div>
		</header>
	);
}

Header.propTypes = {
	data: PropTypes.object,
	uri: PropTypes.string
};

Header.defaultProps = {
	data: {
		OWGraphQL: {}
	},
	uri: '',
};

export {
	Header
};

export default ( props ) => {

	return (
		<StaticQuery
			query={ graphql`
					query HeaderQueryPoweredBy {
						OWGraphQL {
							header: getHeader {
								siteLogoUrl
								siteTagLine
								siteTitle
							}
							headerMenuItems: menuItems(where: {location: OW_MENU_HEADER}, first: 50) {
								nodes {
									key: id
									parentId
									label
									path
									url
								}
							}
						}
					}
				` }
			render={ data => {
				return <Header data={ data } uri={ props.uri } />
			} }
		/>
	)
}
