/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import CloseBtn from './../../icons/close';

import './style.scss';

/**
 * SkipButton component.
 */
const SkipButton = ( { onClick } ) => {

	return(
		<button className="landing-video__skip-btn" onClick={ onClick }>
			Skip <CloseBtn />
		</button>
	);

};

SkipButton.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default SkipButton;
