/**
 * External dependencies.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { each, isEmpty } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import DropdownIcon from '../icons/dropdown-icon';
import { getInternalSlug, isBreakpointMedium, sendContactClickEvent, isExternalUrl } from '../../utils/functions';
// import './style.scss';

/**
 * Nav component.
 *
 * @param { Object } props Header nav menu items.
 *
 * @return {jsx}
 */
const Nav = ({ headerMenuItems, menuVisible, toggleMenu }) => {
	const [menuState, setMenuState] = useState({});

	// Eslint disable as headerMenuItems is available from the props so does not have a missing dependency.
	/* eslint-disable */
	useEffect(() => {

		if (!isEmpty(headerMenuItems)) {
			const newMenuState = {};

			each(headerMenuItems, (item) => {
				newMenuState[item.key] = { isOpen: false };
			});

			setMenuState(newMenuState);
		}

	}, []);
	/* eslint-enable */

	const handleSubMenuOpen = (event, parentId) => {
		event.preventDefault();

		if (!isBreakpointMedium()) {
			return;
		}

		setMenuState({
			...menuState,
			[parentId]: { isOpen: !menuState[parentId].isOpen },
		});
	};

	const menuButtonClasses = classnames(
		'icon-button header-nav-menu-btn hamburger hamburger--slider',
		{
			'is-active': menuVisible
		}
	);

	const navContainerClass = classnames(
		'header-nav',
		{
			'menu-visible': menuVisible
		}
	);

	const toggleMenuOnLinkClick = () => {
		if (isBreakpointMedium()) {
			toggleMenu();
		}

		sendContactClickEvent('Contact Us Nav', 'NAV');
	}

	return (
		<>
			<button
				className={menuButtonClasses}
				type="button"
				onClick={toggleMenu}
				onKeyDown={toggleMenu}
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" className="menu">
					<g fillRule="evenodd"><path d="M0 0H12V1H0zM0 4H12V5H0zM0 8H12V9H0z" transform="translate(0 1)" /></g>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" className="menu-close">
					<g fillRule="evenodd">
						<path d="M0.75 0L9 8.25 8.25 9 0 0.75z" transform="translate(1 1)" />
						<path d="M0 8.25L8.25 0 9 0.75 0.75 9z" transform="translate(1 1)" />
					</g>
				</svg>
			</button>
			<nav className={navContainerClass}>
				<div className="header-nav__container">
					<ul className="header-nav__wrap">
						{!isEmpty(headerMenuItems) && headerMenuItems.map((menu) => {
							const hasChildren = null !== menu.childItems ? menu.childItems.length : false;
							const parentMenuLink = '#' !== menu.path ? (
								menu.isExternalUrl !== true ?
									<Link
										className="header-nav__menu-link"
										to={isExternalUrl(menu.path) ? getInternalSlug(menu.path) : menu.path}
										activeClassName="active"
										onClick={toggleMenuOnLinkClick}
									>
										{menu.label}
									</Link> :
									<a className="header-nav__menu-link" href={menu.path}>{menu.label}</a>
							) : (
								<span className="header-nav__menu-link">
									{menu.label}
								</span>
							);
							const liClassNames = classnames(
								'header-nav__menu-item',
								{
									'menu-has-children': hasChildren
								},
								{
									'child-menu-open': menuState[menu.key] ? menuState[menu.key].isOpen : false
								}
							);
							const liSpanClassNames = classnames(
								'header-nav__menu-link-container',
								{
									'header-nav__menu-link-container--with-arrow': hasChildren
								}
							);
							const subMenuClassNames = classnames(
								'header-nav__submenu',
								{
									'child-menu-open': menuState[menu.key] ? menuState[menu.key].isOpen : false
								}
							);

							return (
								<li key={menu.key} className={liClassNames} >
									<span className={liSpanClassNames}>
										{hasChildren ? (
											<button
												className="header-nav__toggle-menu-btn icon-button"
												onClick={(event) => handleSubMenuOpen(event, menu.key)}
											>
												{parentMenuLink}
												<DropdownIcon />
											</button>
										) : parentMenuLink}
									</span>

									{ /* Child Menu */}
									{hasChildren ? (
										<ul
											className={subMenuClassNames}
										>
											{menu.childItems.map(
												(subMenu) => {
													if (!subMenu.label) {
														return null;
													}

													const isLevel3 = 0 === subMenu.label.indexOf('-');
													const subMenuItemClassNames = classnames('header-nav__submenu-item', {
														'header-nav__submenu-item--level-3': isLevel3,
													});

													return (
														<li
															className={subMenuItemClassNames}
															key={subMenu.key}
														>
															{'Developers' === subMenu.label || 'Frequently asked questions' === subMenu.label ? (
																<a className="header-nav__submenu-link" target="_blank" rel="noopener noreferrer" href={subMenu.path}>
																	{subMenu.label}
																</a>
															) : (
																<Link
																	className="header-nav__submenu-link"
																	to={isExternalUrl(subMenu.path) ? getInternalSlug(subMenu.path) : subMenu.path}
																	activeClassName="active"
																	onClick={toggleMenuOnLinkClick}
																>
																	{isLevel3 ? subMenu.label.trim().replace('- ', '') : subMenu.label}
																</Link>
															)}
														</li>
													);
												}
											)}
										</ul>
									) : (
										''
									)}
								</li>
							);
						})}
					</ul>
				</div>
			</nav>
		</>
	);
};

Nav.propTypes = {
	headerMenuItems: PropTypes.array,
	menuVisible: PropTypes.bool,
	toggleMenu: PropTypes.func,
};

Nav.defaultProps = {
	headerMenuItems: []
};

export default Nav;
